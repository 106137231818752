import React, { Component } from 'react'
import './style.css'

export default class Faq extends Component {


    componentDidMount() {
        window.scrollTo(0, 0)

    }


    onClickHandler = e => {

        const answers = document.getElementsByClassName('Answer')
        
        const answer = e.target.nextElementSibling
        let maxHeight = answer.style.maxHeight

        if (maxHeight === '0px' || !maxHeight) {
            for (const element of answers) {
                element.style.maxHeight = 0
            }
            answer.style.maxHeight = answer.scrollHeight + 'px'
        } else {
            answer.style.maxHeight = 0
        }
    }
   

    render() {

        return (

            <div className='RegFixedContainer'>

                <h1 className='TitleText'>FAQs</h1>

                <div id='FAQContainer'>

                    <p className='Question' onClick={this.onClickHandler}>Is Mustom free to use?</p>
                    <div className='Answer'>
                        <p>Yes, it is free for anyone now. Maybe it will be free forever.</p>
                    </div>

                    <p className='Question' onClick={this.onClickHandler}>Is mustom better than other ecommerce solution?</p>
                    <div className='Answer'>
                        <p>I can say 'No!'. There are many other great ecommerce solutions like Magento, and Shopify.</p>
                        <p>However, if you planning to build a store with maximum flexibility, Mustom could be a good alternative.</p>
                    </div>

                    <p className='Question' onClick={this.onClickHandler}>Is mustom an open source?</p>
                    <div className='Answer'>
                        <p>Yes. Mustom is licensed under the AGPL v3 (GNU AFFERO GENERAL PUBLIC LICENSE Version 3)</p>
                    </div>

                    <p className='Question' onClick={this.onClickHandler}>How/Where can I ask techical stuff?</p>
                    <div className='Answer'>
                        <p>You can use Google Groups (https://groups.google.com/g/mustom).</p>
                    </div>

                    <p className='Question' onClick={this.onClickHandler}>Can I contribute your project?</p>
                    <div className='Answer'>
                        <p>Why not! Please feel free to PR.</p>
                        <p>But please don't use semicolon in your code. This coding standards of this project is 'Standard JS'</p>
                        <p>(unlike Standard JS, however, I use four spaces instead of two spaces)</p>
                    </div>


                </div>

            </div>
        )
    }
}


