import React, { useEffect, useState } from 'react'
import background from '../../media/image/background.mp4'
import MainOne from './MainOne'
import MainTwo from './MainTwo'
import MainThree from './MainThree'
import MainFour from './MainFour'
import Video from './Video'
import './style.css'


const Main = props => {

    return (
        <React.Fragment>
            <div id='BackgroundContainer'>
                <video loop autoPlay muted id='Video'>
                    <source src={background} type='video/mp4' />
                </video>
                <div id='TextContainer'>
                    <h1>Mustom! More Than Custom.</h1>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce et mauris sed massa lobortis molestie. Duis posuere velit at urna commodo sodales. Donec felis diam, luctus eget massa vitae, euismod ultricies orci. Proin luctus purus ac vehicula dapibus. Mauris dignissim ultrices tortor, nec egestas nisi hendrerit vitae.
                    </p>
                </div>
            </div>
            <MainFour />
            <MainOne />
            <MainTwo />
            <MainThree />
            <Video />
        </React.Fragment>
    )
}

export default Main