import React, { Component } from 'react'


export default class NoRoute extends Component {


    componentDidMount() {
        window.scrollTo(0, 0)

    }
   

    render() {


        const TextStyle = {
            textAlign: 'center',
            lineHeight: '100px'
        }

        return (

            <div className='RegFixedContainer' style={{height: '90vh'}}>


                <p style={TextStyle}>404</p>
                

            </div>
        )
    }
}


