import React, { Component } from 'react'
import HeaderPC from './HeaderPC'
import HeaderMobile from './HeaderMobile'

import './style.css'

const Header = props => {

    return props.isMobile ? <HeaderMobile /> : <HeaderPC />
   
}


export default Header