import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './style.css'


export default class Footer extends Component {

    render() {

        return (

            <div id='FooterContainer'>
                <div className='RegContainer'>
                    <span>©2023 Ryu Woosik All Rights Reserved</span>
                    <Link to='/privacy'><span className='FooterMenu'>Privacy Policy</span></Link>
                    <Link to='/tnc'><span className='FooterMenu'>Terms of Use</span></Link>
                </div>
            </div>
        )
    }
}