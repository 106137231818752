import React from 'react'


const Registration= () => {
    return (
        <div style={{marginTop: '140px'}}>
            <input type='text' />


            <p>Store Name</p>
            <p>Store URL</p>
            <p>Register Name</p>
            <p>Email</p>
            <p>Tell us more about your store</p>

        </div>
    )
}


export default Registration


