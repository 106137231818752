import React, { Component } from 'react'
import gitHub from '../../media/image/octocat.png'
import './style.css'


export default class Download extends Component {

    state = {
        version: '0.1.0',
        file_aio: '',
        file_engine: '',
        file_admin: '',
        file_store: '',
        file_old: [],
        changeLog: []
    }


    componentDidMount() {
        window.scrollTo(0, 0)

    }
   

    render() {

        return (

            <div className='RegFixedContainer'>
                <h1 className='TitleText'>Download</h1>
                <p className='SubTitleText'>Your great journey starts here! Get started with Mustom today!</p>


                <div style={{minHeight: '90vh'}}>
                    <div id='GitHub'>
                        <img src={gitHub} alt='gitHub' />                        
                    </div>


                    <div style={{textAlign: 'center', lineHeight: 2, margin: '50px auto'}}>
                        <p>Project Mustom is just beginning.</p>
                        <p>(Current version of Mustom is just 0.0.1)</p>
                        <p>Many of new features, and changes are added on a daily basis.</p>
                        <p>This download page will be available as of version 0.1.0</p>
                        <p>You can download/clone sorcecode from GitHub for now.</p>
                        <p><a href='https://github.com/mustom/mustom'>GitHub : https://github.com/mustom/mustom</a></p>
                    </div>


                </div>


                {/* <div id='DownloadContainer'>
                    <div id='DownloadLeft'>
                        <h2 className='SubTitle' style={{marginBottom: '20px'}}>Download Current Version {this.state.version}</h2>

                        <div className='DownloadBtn'>
                            <span className='material-icons'>folder</span><span>All in One</span>
                        </div>
                        <div className='DownloadBtn'>
                            <span className='material-icons'>folder</span><span>Engine</span>
                        </div>
                        <div className='DownloadBtn'>
                            <span className='material-icons'>folder</span><span>Admin Panel</span>
                        </div>
                        <div className='DownloadBtn'>
                            <span className='material-icons'>folder</span><span>Storefront</span>
                        </div>
                        <div style={{marginTop: '20px'}}>
                            <span>'All in One' includes engine, admin panel, and storefront demo.</span>
                        </div>

                        <div>

                        </div>
                        
                        <h2 className='SubTitle' style={{margin: '100px 0 20px 0'}}>Change Log</h2>

                    </div>


                    <div id='DownloadRight'>
                        <h2 className='SubTitle'>Download Previous Version</h2>



                    </div>
                </div> */}



            </div>
        )
    }
}


