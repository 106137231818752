import React, { useState } from 'react'
import QandA from './QandA'
import Registration from './Registration'
import Record from './Record'
import './style.css'


const Register = () => {

    const [ menu, toggleMenu ] = useState('main')


    const Menu = () => {
        if (menu === 'registarion') {
            return (
                <Registration />
            )
        }

        if (menu === 'record') {
            return (
                <Record />
            )
        }

        return (
            <QandA />
        )
    }


    return (
        <div className='RegFixedContainer' style={{minHeight: '90vh', marginBottom: '100px'}}>
            <h1 className='TitleText'>Register My Store</h1>
            <p className='SubTitleText'>It's free! Feel free to register now!</p>
            <div className='RegisterContainer'>
                <p>Mustom is the AGPL v3 (GNU Affero General Public License Version 3) licensed. That means you need to disclose your source code when you go live. But you don't need to worry about that. You can get an exemption from source code disclosure by registering your store.</p>
                <p>It is completely free of charge, and we will not ask detailed information about you, and your store.</p>
            </div>
            <div id='RegisterButtonContainer'>
                <span onClick={() => toggleMenu('registarion')}>Regiter My Store</span>
                <span onClick={() => toggleMenu('register')}>Search My Record</span>
            </div>
            <Menu />
        </div>
    )
}


export default Register


