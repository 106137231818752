import React, { Component } from 'react'
import picAdmin from '../../media/image/demo.jpg'
import picStore from '../../media/image/demo_2.jpg'
import './style.css'

export default class Faq extends Component {


    componentDidMount() {
        window.scrollTo(0, 0)
    }


    moveToAdmin = () => {
        window.open('https://berrysix.com/admin', '_blank')
    }

    
    moveToStorefront = () => {
        window.open('https://berrysix.com', '_blank')
    }
   

    render() {

        return (

            <div className='RegFixedContainer'>

                <h1 className='TitleText'>Mustom Demo</h1>

                <blockquote>
                   See the features, before your install Mustom.
                </blockquote>

                <div id='DemoContainer'>

                    <div id='DemoLeft'>
                        <div className='DemoImage' style={{backgroundImage : 'url(' + picAdmin + ')'}} >
                        </div>
                        <div className='DemoText'>
                            <h2 className='SubTitle'>Admin Panel</h2>
                            <p>ID : admin</p>
                            <p>Password : admin</p>
                        </div>
                        <div className='DemoButton'>
                            <span onClick={this.moveToAdmin}>Access</span>
                        </div>
                    </div>

                    <div id='DemoRight'>
                        <div className='DemoImage' style={{backgroundImage : 'url(' + picStore + ')'}} >
                        </div>
                        <div className='DemoText'>
                            <h2 className='SubTitle'>Storefront</h2>
                            <p>ID : mustom</p>
                            <p>Password : mustom</p>
                        </div>
                        <div className='DemoButton'>
                            <span onClick={this.moveToStorefront}>Access</span>
                        </div>

                    </div>

                </div>

                <div style={{minHeight: '500px'}}>
                    <p>Please note that the test environment is for EVERYONE. Please do not try to do any CRAZY things.</p>
                    <p>You can place test order on storefront. And, see it thourugh admin panel. No real payments will made.</p>
                </div>

            </div>
        )
    }
}


