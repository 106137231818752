import React from 'react'
import { Link } from 'react-router-dom'


const MobileMenu = props => {

    const moveToDocumentation = () => {
        window.open('https://mustom.gitbook.io/mustom/', '_blank')
        props.closeMe()
    }

    const moveToForum = () => {
        window.open('https://groups.google.com/g/mustom', '_blank')
        props.closeMe()
    }

    return (
        <div id='MenuContainer'>

            <div id='MenuTop' onClick={() => props.closeMe()}>
                <p>CLOSE MENU</p>
            </div>

            <div id='MenuBody'>

                <Link to='/'><h4 onClick={() => props.closeMe()}>Home</h4></Link>
                <Link to='/feature'><h4 onClick={() => props.closeMe()}>Features</h4></Link>
                <Link to='/download'><h4 onClick={() => props.closeMe()}>Download</h4></Link>
                <h4 onClick={moveToDocumentation}>Documentation</h4>
                <Link to='/faq'><h4 onClick={() => props.closeMe()}>FAQs</h4></Link>
                <Link to='/notice'><h4 onClick={() => props.closeMe()}>News & Notice</h4></Link>
                {/* <Link to='/app'><h4 className='HeaderMenu'>Apps</h4></Link> */}
                <Link to='/license'><h4 onClick={() => props.closeMe()}>License</h4></Link>
                <Link to='/about'><h4 onClick={() => props.closeMe()}>About & Contact Me</h4></Link>
                <h4 onClick={moveToForum}>Forum</h4>
                <Link to='/register'><h4 onClick={() => props.closeMe()}>Register My Store</h4></Link>

            </div>
        </div>
    )
}


export default MobileMenu