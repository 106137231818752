import React from 'react'
import YouTube from 'react-youtube'

const Video = () => {

    const options = {
        height: '720',
        width: '1280',
        playerVars: {
          autoplay: 1,
          controls: 1
        }
    }
  
    return (
        <div className='BlackContainer'>
            <h1>Watch How Mustom Works!</h1>
            <p>7min Video Clip</p>
            <YouTube videoId='XOTpGNdAN_I' opts={options} />
        </div>
    )
}


export default Video