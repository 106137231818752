import React from 'react'
import Img from './MainOne.png'


const MainOne = () => {

    return (
        <div className='GrayContainer'>
            <div className='SuperImageContainer Right'>
                <img src={Img} alt='Custom ecommerce' />    
            </div>
            <div className='RegContainer' style={{height: '800px'}}>
                <div className='SuperTextContainer Left'>
                    <h1 className='SuperText'>Maximum</h1>
                    <h1 className='SuperText'>Flexibility!</h1>
                    <p className='SubSuperText'>
                        "Even if you have remarkerble ideas, you could not realize it, if there are limitations on your ecommece platform.
                        If your conuntry have unique tax system, or regulation, most of ecommerce will not meet your expectation.
                        It is hard to build your store just as you imagined, if you build your store is built with platforms, or solutions.
                        You have two options. Fit your business into platform's feature, or build your own system with huge amount of money and lot's of developers.
                        However, Mustom can be your third option for your choice. With flexible enough structures of Mustom, you may not change your business process, or marketing strategy."
                    </p>
                </div>
            </div>
        </div>
    )
}

export default MainOne
