import React from 'react'
import Img from './MainFour.png'


const MainFour = () => {

    return (
        <div className='WhiteContainer'>
            <div className='SuperImageContainer Left'>
                <img src={Img} alt='Ryu Woosik' />    
            </div>
        <div className='RegContainer' style={{height: '800px'}}>
            <div className='SuperTextContainer Right'>
            <h1 className='SuperText'>We Know</h1>
            <h1 className='SuperText'>What You Want!</h1>
            <p className='SubSuperText'>
                "We believe that the primary goal of every business should be a 'Money'. Exchange something to money, to be specific.
                Good looking UI, and well structured architecture will help you to archive this goal.
                But, it is still not enough. Many of your competitors will do this just like you if they are not a stupid.
                (if they did not, they will benchmark your store soon.)
                Now, it is time to think about 'Super difference' and Mustom will be a good toolkit to be a 'Super difference'.
                Hope that Mustom can be your money machine."
            </p>
            </div>
        </div>
        </div>
    )
}


export default MainFour