import React, { Component } from 'react'


export default class Privacy extends Component {


    componentDidMount() {
        window.scrollTo(0, 0)

    }
   

    render() {

        const TextStyle = {
            textAlign: 'center',
            lineHeight: '100px'
        }

        return (

            <div className='RegFixedContainer' style={{height: '90vh'}}>

                <h1 className='TitleText'>Privacy Policy</h1>

                <p style={TextStyle}>I'm not interested in your personal data.</p>
                

            </div>
        )
    }
}


