import React, { Component } from 'react'


export default class Notice extends Component {

    state = {
        content: [
            {date: '20 Jan, 2022', title: 'Fusce et mauris sed massa lobortis molestie'},
            {date: '10 Jan, 2022', title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'}
        ]
    }


    componentDidMount() {
        window.scrollTo(0, 0)

    }
   

    render() {


        const ListMap = this.state.content.map( data => {


            return(
                <tr>
                    <td>{data.date}</td>
                    <td>{data.title}</td>
                </tr>
            )
        })


        return (

            <div className='RegFixedContainer' style={{minHeight: '80vh'}}>

                <h1 className='TitleText'>News and Notices</h1>
                <p className='SubTitleText'>Handpicked features for your own store!</p>

                <table width='100%'>
                    <thead>
                        <tr>
                            <th width='20%'>Date</th>
                            <th width='80%'>Title</th>
                        </tr>
                    </thead>
                    <tbody>
                        { ListMap }              
                    </tbody>
                </table>  

            </div>
        )
    }
}


