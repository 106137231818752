import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from './MobileMenu'
import Logo from './logo_black.svg'
import Menu from './menu.svg'



export default class HeaderMobile extends Component {

    state = {
        isOpen: false
    }


    toggleMenu = () => {
        this.setState({ isOpen: this.state.isOpen ? false : true })
    }  



    render() {

        const menuContainer = {
            float: 'left',
            width: '40px',
            height: '40px',
            margin: '10px 0 10px 10px',
            padding: '10px'
        }

        const logoContainer = {
            margin: '0 auto',
            padding: '10px',
            textAlign: 'center',
            width: '60px',
            height: '100%'
        }

        return (
            <div id='HeaderContainer'>
                { this.state.isOpen ? <MobileMenu closeMe = {this.toggleMenu} /> : null }
                <div style={menuContainer} onClick={this.toggleMenu}>
                    <img src={Menu} alt='node ecommerce' />
                </div>
                <div style={logoContainer}>
                    <Link to='/'><img src={Logo} alt='mustom' /></Link>
                </div>
            </div>
        )
    }
}