import React from 'react'


const QandA= () => {
    return (
        <div style={{marginTop: '140px'}}>
            <p className='RegisterQuestion'>Should I register my store?</p>
            <p>If your store is not opened to public, you don't need to register. If not, you need to register.</p>
            <p className='RegisterQuestion'>Do I need to register again, if I upgrade newer version of Mustom?</p>
            <p>It is onetime registration. You don't need to register your store again unless your domain is changed.</p>
            <p className='RegisterQuestion'>Is it free to register? Is there any hidden fee?</p>
            <p>It is completely free, and there is no hidden fee. It will be free forever.</p>
            <p className='RegisterQuestion'>I just launched another store. Do I need to register new one?</p>
            <p>If you use new domain, you need to register new one too.</p>
            <p className='RegisterQuestion'>My store don't have domain name because it's not a web app. How can I register my store?</p>
            <p>You can specify app name instead of domain name.</p>
            <p className='RegisterQuestion'>I want to develop an ecommerce platform based on Mustom. Can I get an exemption from source code disclosure?</p>
            <p>It is impossible. The exemption can be applied for online stores only.</p>
            <p className='RegisterQuestion'>If I register my store, the license will be changed?</p>
            <p>No, the license of Mustom is still AGPL v3. You should use Mustom under the terms of the AGPL v3 except source code disclosure.</p>
            <p className='RegisterQuestion'>What will happened if I register my store?</p>
            <p>You will get emails sometimes, if you agreed to subscribe news letter. But, It will not frequent, and you can unsubscribe if you want.</p>
            <p className='RegisterQuestion'>How to register my store?</p>
            <p>You can register through Mustom HQ. Here is Mustom HQ!</p>
        </div>
    )
}


export default QandA


