import React from 'react'
import Img from './MainThree.png'

const MainThree = () => {

    return (
        <div className='GrayContainer'>
            <div className='SuperImageContainer Right'>
                <img src={Img} alt='Tech Stacks' />    
            </div>
            <div className='RegContainer' style={{height: '800px'}}>
                <div className='SuperTextContainer Left'>
                <h1 className='SuperText'>Our Experiments</h1>
                <h1 className='SuperText'>are Your Chances!</h1>
                <p className='SubSuperText'>
                    "There are many of great ecommerce platforms like Magento and Shopify.
                    Many of developers, and contributors are making these platform better, and more safer.
                    While Mustom project is just beginning. It will be hard to gain popularity as they have.
                    But, it is not important, because the goal of this project is not a popularity.  
                    The goal of this project is to be a future of ecommerce. Add new features that ever exist.
                    To do so, we will keep experiment new features for the future."
                </p>
                </div>
            </div>
        </div>
    )
}

export default MainThree