import React, { Component } from 'react'


export default class About extends Component {


    componentDidMount() {
        window.scrollTo(0, 0)

    }
   

    render() {

        return (

            <div className='RegFixedContainer' style={{minHeight: '90vh'}}>

                <h1 className='TitleText'>About</h1>

                <div>
                    <h5>Hi, I'm Ryu Woosik, the mother of Mustom.</h5>
                    <h5>Since the year 2008, I have been working in the e-commerce field as a owner, team leader, and business engineer. 
                        Mustom was one of a big advanture for my life, because it was really hard to build a platform from scratch.
                        But, it was really exciting experience to build my own ecommerce platform.
                        I will be very happy if Mustom helps your business.
                    </h5>
                </div>

                <div style={{marginTop: '50px', padding: '10px'}}>
                    <h5 style={{marginBottom: '20px'}}>My Career</h5>

                    <p><strong>LG CNS Co.,Ltd. </strong><span> (Aug 2019 - Current)</span></p>
                    <span>Business Engineering Professional | Ecommerce Project(s)</span>

                    <p>&nbsp;</p>

                    <p><strong>YG-1 Co.,Ltd. </strong><span> (Sep 2014 - Aug 2019)</span></p>
                    <span>Deputy General Manager | Chinese Ecommerce Team Leader</span>

                    <p>&nbsp;</p>

                    <p><strong>Chance House Co.,Ltd. </strong><span> (Mar 2008 - Aug 2014)</span></p>
                    <span>Founder and CEO | Global Ecommerce</span>

                    <p>&nbsp;</p>

                    <p><strong>LG Electronics Inc. </strong><span> (Feb 2003 - Feb 2008)</span></p>
                    <span>Assistant Manager | DID(Digital Information Display) B2B Sales</span>
                </div>

                <div style={{marginTop: '50px', padding: '10px'}}>
                    <h5 style={{marginBottom: '20px'}}>Contact</h5>
                    <p><strong>eMail </strong><span>ryuwoosil@gmail.com</span></p>
                    <p><strong>LinkedIn </strong><span>https://www.linkedin.com/in/chancehouse/</span></p>
                    <p><strong>Homepage </strong><span>https://submem.com</span></p>
                </div>

            </div>
        )
    }
}


