import React, { Component } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './component/header'
import Footer from './component/footer'
import Main from './component/main'
import Demo from './component/demo'
import Download from './component/download'
import Faq from './component/faq'
import Feature from './component/feature'
import License from './component/license'
import Notice from './component/notice'
import About from './component/misc/about'
import Register from './component/register'
import Privacy from './component/misc/privacy'
import TnC from './component/misc/tnc'
import NoRoute from './component/misc/noRoute'

export default class App extends Component {

    state = {
        isMobile : false
    }

    componentDidMount() {
        const winWidth = window.innerWidth
        this.setState({ isMobile: winWidth <= 992 ? true : false })
    }
   


    render() {

        return (

            <Router>

                <Header isMobile={this.state.isMobile} />

                    <Routes>                                      
                        <Route path='/' element={<Main />} />
                        {/* <Route path='/demo' element={<Demo />} /> */}
                        <Route path='/download' element={<Download />} />
                        <Route path='/faq' element={<Faq />} />
                        <Route path='/feature' element={<Feature />} />
                        <Route path='/license' element={<License />} />
                        <Route path='/notice' element={<Notice />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/register' element={<Register />} />
                        <Route path='/privacy' element={<Privacy />} />
                        <Route path='/tnc' element={<TnC />} />
                        <Route path='' element={NoRoute} />
                    </Routes>

                <Footer />

            </Router>
        )
    }
}


