import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Logo from './logo_black.svg'
import './style.css'


const HeaderPC = () =>{

    const moveToDocumentation = () => {
        window.open('https://mustom.gitbook.io/mustom/', '_blank')
    }


    const moveToForum = () => {
        window.open('https://groups.google.com/g/mustom', '_blank')
    }

    return (

        <div id='HeaderContainer'>

            <div className='RegContainer'>

                <div id='LogoContainer'>
                    <Link to='/'>
                        <img src={Logo} alt='mustom' />
                    </Link>
                </div>

                    <Link to='/feature'><span className='HeaderMenu'>Features</span></Link>
                    <Link to='/download'><span className='HeaderMenu'>Download</span></Link>
                    <span className='HeaderMenu' onClick={moveToDocumentation}>Documentation</span>
                    <Link to='/faq'><span className='HeaderMenu'>FAQs</span></Link>
                    <Link to='/notice'><span className='HeaderMenu'>News & Notice</span></Link>
                    {/* <Link to='/app'><span className='HeaderMenu'>Apps</span></Link> */}
                    <Link to='/license'><span className='HeaderMenu'>License</span></Link>
                    <Link to='/about'><span className='HeaderMenu'>About Me</span></Link>
                    <span className='HeaderMenu' onClick={moveToForum}>Forum</span>

                <div id='RegisterButton'>
                    <Link to='/register'><span className='HeaderMenu'>Register My Store</span></Link>
                </div>

            </div>

        </div>

    )
}

export default HeaderPC