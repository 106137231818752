import React from 'react'
import Img from './MainTwo.png'

const MainTwo = () => {

    return (
        <div className='WhiteContainer'>
            <div className='SuperImageContainer Left'>
                <img src={Img} alt='Tech Stacks' />    
            </div>
            <div className='RegContainer' style={{height: '800px'}}>
                <div className='SuperTextContainer Right'>
                    <h1 className='SuperText'>The Best</h1>
                    <h1 className='SuperText'>of The Best!</h1>
                    <p className='SubSuperText'>
                        "Don't build your store with old school stacks. Mustom is built with set of most popular, and powerful stacks.
                        You may know that Javascript is most popular development language now. Many of companies are using Node.js for a backend system, and React is most popular frontend library. 
                        It can be a big advantage when you use Mustom. One tool(Javascript) can cover all(storefront, backend, and admin panel).
                        Other stacks like Redis, Elasticsearch, and rabbitMQ will make your store more faster, and stronger."
                    </p>
                </div>
            </div>
        </div>
    )
}

export default MainTwo