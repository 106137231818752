import React, { Component } from 'react'


export default class TnC extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)

    }
   

    render() {

        const TextStyle = {
            textAlign: 'center',
            lineHeight: '100px'
        }

        return (

            <div className='RegFixedContainer' style={{height: '90vh'}}>

                <h1 className='TitleText'>Terms of Use</h1>

                <p style={TextStyle}>This website don't have terms of use!</p>

            </div>
        )
    }
}


