import React, { Component } from 'react'
import './style.css'


export default class Feature extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)

    }

    render() {

        return (

            <div className='RegFixedContainer' style={{minHeight: '90vh'}}>
                <h1 className='TitleText'>Features</h1>
                <p className='SubTitleText'>Handpicked features for your own store!</p>

                <div id='FeatureContainer'>
                    <div className='FeatureBlock'>
                        <ul>
                            Customer
                            <li>Customer Group</li>
                            <li>Wish List</li>
                            <li>Reviews & Rates</li>
                            <li>Closure Account Confirmation</li>
                        </ul>
                    </div>
                    <div className='FeatureBlock'>
                        <ul>
                            Order
                            <li>Buy Now</li>
                            <li>Back Order</li>
                            <li>Guest Checkout</li>
                            <li>Customizable Order Status</li>
                            <li>Customizable Dispute Status</li>
                            <li>Partial Cancel (Product/Qty)</li>
                            <li>Partial Return</li>
                            <li>Partial Replacement</li>
                            <li>Order Splitting</li>
                            <li>Abnormal Orders Monitoring</li>
                            <li>PDF Invoice</li>
                            <li>PDF CreditMemo</li>
                        </ul>
                    </div>
                    <div className='FeatureBlock'>
                        <ul>
                            Product
                            <li>Manage Category</li>
                            <li>Special Price</li>
                            <li>Product Option</li>
                            <li>Out-of-Stock threshold</li>
                        </ul>
                    </div>
                    <div className='FeatureBlock'>
                        <ul>
                            Tax
                            <li>Variable Tax</li>
                            <li>Tax Rule CSV Upload</li>
                            <li>Fixed Product Tax</li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    <div className='FeatureBlock'>
                        <ul>
                            Marketing/Promotion
                            <li>Discount Rules</li>
                            <li>Coupon Code Generator</li>
                            <li>Coupon Code CSV Upload</li>
                            <li>Newsletter Subscription</li>
                            <li></li>
                        </ul>
                    </div>
                    <div className='FeatureBlock'>
                        <ul>
                            CMS
                            <li>Static Page Generator</li>
                            <li>Static Block Generator</li>
                            <li>Store Policy (e.g. Privacy Policy)</li>
                            <li>News/Notice</li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    <div className='FeatureBlock'>
                        <ul>
                            Report
                            <li>Dashboard</li>
                            <li>Product Report</li>
                            <li>Sales Report</li>
                            <li>Customer Report</li>
                            <li>Search Keywords</li>
                        </ul>
                    </div>
                    <div className='FeatureBlock'>
                        <ul>
                            Localization
                            <li>Admin Panel Translation</li>
                            <li>Currency / Currency Expression</li>
                            <li>Allow/Disallow Country</li>
                            <li>Customizable Country/Province</li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    <div className='FeatureBlock'>
                        <ul>
                            Security
                            <li>Token Based Authentication</li>
                            <li>Admin Role & Permission</li>
                            <li>Admin Action Log</li>
                            <li>Email Verification</li>
                            <li>Password Reset Link</li>
                            <li>SHA/AES Encryption</li>
                            <li>Rate Limit</li>
                        </ul>
                    </div>
                    <div className='FeatureBlock'>
                        <ul>
                            Apps
                            <li>Built-In BrainTree Integration</li>
                            <li>Built-In Bank Transfer</li>
                            <li>Built-In Free Shipping</li>
                            <li>Built-In Flat Rate Shipping</li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    <div className='FeatureBlock'>
                        <ul>
                            System
                            <li>Maintenance Mode</li>
                            <li>SMTP (Simple Mail Transfer Protocol)</li>
                            <li>Daily Log</li>
                            <li>High Latency Task Queue</li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    <div className='FeatureBlock'>
                        <ul>
                            Misc.
                            <li>Export Product</li>
                            <li>Export Customer</li>
                            <li>Export Sales</li>
                            <li>Channels</li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>





                </div>
            </div>
        )
    }
}


